import React, { useEffect, useState } from 'react';
import { Cloudinary } from "@cloudinary/url-gen";
import { fill } from "@cloudinary/url-gen/actions/resize";

const CLOUDINARY_URL = process.env.CLOUDINARY_URL;
const CLOUD_NAME = CLOUDINARY_URL.split('@')[1]

const CloudinaryImage = ({ className, cloudinaryImageId, width, height }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const cld = new Cloudinary({
    cloud: {
      cloudName: CLOUD_NAME
    }
  });

  useEffect(() => {
    const image = cld.image(cloudinaryImageId);
    image.resize(fill().width(width).height(height));
    setImageUrl(image.toURL());
  }, []);

  return (
    <img className={className} src={imageUrl} />

  );
};

export default CloudinaryImage;
