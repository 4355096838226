import React from 'react';
import Rating from 'react-rating';

const moveImageUrlToHttps = (image) => image ? image.replace("http://", "https://") : null;
const emptyImageURL = "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg";

const SearchHit = ({ hit, addRefinement, ...props }) => {
  function redirectToMastermind(event, id) {
    if (!document.redirectModal || event.button != 0) return;

    event.stopPropagation();
    event.preventDefault();
  };

  function customURL(hit) {
    return location.protocol + "//" + hit.custom_domain + "/masterminds/" + hit.id;
  }

  const discountLabel = () => {
    return (hit.discount_valid_from == null || new Date(hit.discount_valid_from) <= new Date(Date.now())) && (hit.discount_valid_till == null || new Date(hit.discount_valid_till) >= new Date(Date.now()))
      && (!hit.discount_is_limited || hit.emails_white_list_for_discount.includes(document.current_user_email)) && hit.price > hit.discounted_price && (
        <span className="position-absolute bottom-0 start-0 mb-3 ml-3 badge bg-success">
          {`${hit.discount_applicable} Off`}
        </span>
      )
  }

  return (
    <article className="directory__card card overflow-hidden h-100 rounded-1">
      <div className="ratio ratio-4x3">
        <div>
          <img className="w-100 h-100" src={moveImageUrlToHttps(hit.directory_image) || emptyImageURL} alt={hit.name} />
          {discountLabel()}
        </div>
      </div>
      <div className="card-body d-flex flex-column p-0">
        <div className="flex-grow-1 p-3">
          <a className="cursor-pointer" href={customURL(hit)} target='_blank' onMouseDown={(event) => redirectToMastermind(event, hit.id)}>
            <h6 className="mb-1">{hit.name}</h6>
          </a>
          {document.showAuthorName &&
            <div>
              <a className="fw-medium cursor-pointer small" onClick={(event) => {
                event.preventDefault();
                addRefinement('primary_expert_name', hit.primary_expert_name);
              }}>
                By {hit.primary_expert_display_name}
              </a>
            </div>
          }
          <p className="directory__card__description mt-2 mb-0 overflow-hidden small">{hit.short_description}</p>
        </div>
        <div className="p-3 border-top">
          <div className="row align-items-center justify-content-between">
            <div className="col-7 pr-0">
              {((hit.discount_is_limited && !hit.emails_white_list_for_discount.includes(document.current_user_email)) || hit.price <= hit.discounted_price || (hit.discount_valid_from != null && new Date(hit.discount_valid_from) > new Date(Date.now())) ||
                (hit.discount_valid_till != null && new Date(hit.discount_valid_till) < new Date(Date.now()))) && (
                  <h6>
                    {hit.price > 0 ? hit.formatted_price : 'FREE'}
                  </h6>
                )}
              {(hit.discount_valid_from == null || new Date(hit.discount_valid_from) <= new Date(Date.now())) && (hit.discount_valid_till == null || new Date(hit.discount_valid_till) >= new Date(Date.now()))
                && (!hit.discount_is_limited || hit.emails_white_list_for_discount.includes(document.current_user_email)) && hit.price > hit.discounted_price &&
                <div className="d-flex">
                  <h6 className="me-1">
                    {hit.discounted_price > 0 ? hit.currency_symbol : ''}
                    {hit.discounted_price > 0 ? hit.discounted_price : 'FREE'}
                  </h6>
                  <h6 className='text-decoration-line-through fw-normal'>
                    {hit.currency_symbol}{hit.price}
                  </h6>
                </div>
              }
            </div>
            <div className="col-5 text-right directory-rating pr-0">
              {document.showRatingsEnabled && hit.average_rating && (
                <div className="d-flex align-items-center text-nowrap">
                  <Rating
                    initialRating={hit.average_rating}
                    fractions={2}
                    readonly
                    emptySymbol="bi bi-star text-yellow"
                    fullSymbol="bi bi-star-fill text-yellow"
                  />
                  <span className="ms-1 fw-medium small">({hit.ratings_count})</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <a className="position-absolute top-0 start-0 w-100 h-100" href={customURL(hit)} target='_blank' />
    </article>
  )
};

export default SearchHit;
