import { hot } from 'react-hot-loader/root';
import React, { useRef } from 'react';
import {
  InstantSearch,
  Pagination,
  ClearRefinements,
  Panel,
  Configure,
  RatingMenu
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  SortBy,
  HitsPerPage,
  ClearFiltersMobile,
  PriceSlider,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
  SearchHit,
  DateRangeFilter,
} from './widgets';
import CustomHits from './widgets/CustomHits';
import CustomRefinementList from "./widgets/CustomRefinementList";
import CustomSearchBox from "./widgets/CustomSearchBox";
import withURLSync from './URLSync';
import videoBackground from './assets/city-loop-white-side-2021-09-03-21-34-22-utc.webm'
import logo from './assets/new-logo.svg'

const Filters = (props) => (
  <div>
    <h4 className="directory__header mb-5 border border-1 border-top-0 border-end-0 border-start-0">Filters</h4>
    <div className="d-none" data-layout="desktop">
      <ClearRefinements
        translations={{
          reset: (
            <>
              <i className="bi bi-arrow-clockwise" />
              Clear filters
            </>
          ),
        }}
      />
    </div>
    <div data-layout="mobile">
      <ResultsNumberMobile />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Status</label>
      <CustomRefinementList
        attribute="compliance_status"
        transformItems={items => props.orderStatusItems(items)}
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Filter options</label>
      <CustomRefinementList
        attribute="compliance_filter"
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Reviewers</label>
      <CustomRefinementList
        searchable={true}
        attribute="reviewer"
      />
    </div>

    {document.showAuthorName && <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Authors</label>
      <CustomRefinementList
        attribute="primary_expert_name"
        searchable={true}
        translations={{
          placeholder: 'Search for authors...',
        }}
        transformItems={items =>
          items.map(item => ({
            ...item,
            key: item.value,
            _highlightResult: {
              label:
                item._highlightResult ? { value: item._highlightResult.label.value.split('#')[0] } : null
            },
            label: (item.label.split('#')[0]),
          }))
        }
      />
    </div>}

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Created</label>
      <DateRangeFilter attribute="created_at_i" />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Updated</label>
      <DateRangeFilter attribute="updated_at_i" />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Organizations</label>
      <CustomRefinementList
        attribute="team_name"
        searchable={true}
        translations={{
          placeholder: 'Search for organizations...',
        }}
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Free or Paid</label>
      <CustomRefinementList
        attribute="free_or_paid"
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Price</label>
      <PriceSlider attribute="price" />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Categories</label>
      <CustomRefinementList
        attribute="category_names"
        searchable={true}
        translations={{
          placeholder: 'Search for categories...',
        }}
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Recurring Payments?</label>
      <CustomRefinementList
        attribute="recurring_payments"
      />
    </div>

    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Tags</label>
      <CustomRefinementList
        attribute="tags_array"
        searchable={true}
        translations={{
          placeholder: 'Search for tags...',
        }}
        limit={10}
      />
    </div>

    {document.showRatingsEnabled && (
      <div className="mt-5">
        <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">Tags</label>
        <RatingMenu attribute="rounded_rating" />
      </div>
    )}
  </div>
)

const Directory = props => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const searchClient = algoliasearch(
    props.applicationID,
    props.securedApiKey
  );

  const addRefinement = props.addRefinement;
  const Hit = React.useRef(props => {
    return (
      <SearchHit
        {...props}
        addRefinement={addRefinement}
      />
    )
  });

  function openFilters() {
    document.body.classList.add('filtering');
    window.scrollTo(0, 0);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('click', onClick);
  }

  function closeFilters() {
    document.body.classList.remove('filtering');
    containerRef.current.scrollIntoView();
    window.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('click', onClick);
  }

  function onKeyUp(event) {
    if (event.key !== 'Escape') {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }

  function orderStatusItems(list) {
    var order = ['Being Reviewed', 'Awaiting Review', 'Approved', 'Denied', 'No Review Required']
    return list.sort(function (a, b) {
      return order.indexOf(a.label) - order.indexOf(b.label)
    })
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="mastermind_popularity_desc"
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <div className="align-items-center d-flex hero justify-content-center position-relative px-3 px-md-0" ref={headerRef}>
        <div className="py-2 position-absolute top-0 start-0 mt-3 mt-md-5 ms-3 ms-md-5">
          <img src={logo} height="20" />
        </div>
        <div className="position-absolute w-100 h-100 overflow-hidden hero__video">
          <video className="w-100" autoPlay loop muted="muted">
            <source id="webm" src={videoBackground} type="video/webm" />
          </video>
        </div>
        <div className="hero__gradient" />
        <div className="hero__content w-100 pt-6 pt-md-5 text-center">
          <div className="container">
            <h1 className="hero__title mb-6 fw-medium mt-6 mt-md-0">Looking for a Mastermind? - <span className="text-primary">find it.</span></h1>
            <div className="row justify-content-center mb-5">
              <div className="col-12 col-md-7">
                <CustomSearchBox
                  translations={{
                    placeholder: 'Title, description, tags…',
                  }}
                  submit={<i className="bi bi-search" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Configure
        attributesToSnippet={['short_description:-1', 'compliance_status']}
        snippetEllipsisText="..."
        removeWordsIfNoResults="allOptional"
      />
      <main className="container p-3 px-md-0 py-md-4" ref={containerRef}>
        <div className="row">
          <aside className="directory__aside col-auto d-none d-md-block">
            <div onKeyUp={onKeyUp}>
              <Filters {...props} orderStatusItems={orderStatusItems} />
            </div>
          </aside>

          <section className="directory__content col">
            <header className="row justify-content-end mb-4">
              <div className="col-6 col-md-auto directory__dropdown">
                <SortBy
                  className="container-option"
                  defaultRefinement="mastermind_updated_asc"
                  items={[
                    {
                      label: 'Oldest in Review',
                      value: 'mastermind_updated_asc',
                    },
                    {
                      label: 'Newest in Review',
                      value: 'mastermind_updated_desc',
                    },
                    {
                      label: 'Price ascending',
                      value: 'mastermind_by_price_asc',
                    },
                    {
                      label: 'Price descending',
                      value: 'mastermind_by_price_desc',
                    },
                    {
                      label: 'Popularity',
                      value: 'mastermind_popularity_desc',
                    },
                    {
                      label: 'Latest',
                      value: 'mastermind_created_desc',
                    },
                    {
                      label: 'Rating',
                      value: 'mastermind_rating_desc',
                    },
                  ]}
                />
              </div>
              <div className="col-6 col-md-auto directory__dropdown">
                <HitsPerPage
                  className="container-option"
                  items={[
                    {
                      label: '16 hits per page',
                      value: 16,
                    },
                    {
                      label: '32 hits per page',
                      value: 32,
                    },
                    {
                      label: '64 hits per page',
                      value: 64,
                    },
                  ]}
                  defaultRefinement={16}
                />
              </div>
            </header>
            <CustomHits hitComponent={Hit.current} />
            <NoResults />

            <footer>
              <Pagination
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
            </footer>
          </section>
        </div>
      </main>
      <div className="position-fixed bottom-0 start-50 translate-middle-x p-3 pb-4 d-md-none">
        <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">
          <i className="bi bi-funnel me-2"></i> Filters
        </button>
      </div>
      <div className="offcanvas offcanvas-bottom h-100 d-md-none" tabIndex="-1" id="offcanvasFilters" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header justify-content-end">
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <Filters {...props} orderStatusItems={orderStatusItems} />
        </div>
        <div className="offcanvas-footer p-3">
          <button className="btn btn-primary w-100" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">
            Apply Filters
          </button>
        </div>
      </div>
    </InstantSearch>
  );
};

export default hot(withURLSync(Directory));
