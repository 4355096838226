import { hot } from 'react-hot-loader/root';
import React, { useRef } from 'react';
import {
  InstantSearch,
  Pagination,
  ClearRefinements,
  Configure,
  RatingMenu
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import {
  ButtonsRefinementList,
  SortBy,
  HitsPerPage,
  PriceSlider,
  NoResults,
  ResultsNumberMobile,
  SearchHit
} from './widgets';
import CustomHits from "./widgets/CustomHits";
import CustomSearchBox from "./widgets/CustomSearchBox";
import CustomRefinementList from "./widgets/CustomRefinementList";
import CloudinaryImage from './widgets/CloudinaryImage';
import withURLSync from './URLSync';
import videoBackground from './assets/city-loop-white-side-2021-09-03-21-34-22-utc.webm'
import logo from './assets/new-logo.svg'

const sortByValues = [
  {
    label: 'Popularity',
    value: 'mastermind_popularity_desc',
  },
  {
    label: 'Price ascending',
    value: 'mastermind_by_price_asc',
  },
  {
    label: 'Price descending',
    value: 'mastermind_by_price_desc',
  },
  {
    label: 'Latest',
    value: 'mastermind_created_desc',
  },
  {
    label: 'Rating',
    value: 'mastermind_rating_desc',
  },
]

const hitsPerPageValues = [
  {
    label: '12 hits per page',
    value: 12,
  },
  {
    label: '24 hits per page',
    value: 24,
  },
  {
    label: '48 hits per page',
    value: 48,
  },
]

const Hero = ({ headerRef, team }) => (
  <div className={`align-items-center d-flex hero justify-content-center position-relative px-3 px-md-0 ${!!team && 'hero--sm'}`} ref={headerRef}>
    <div className="py-2 position-absolute top-0 start-0 mt-3 mt-md-5 ms-3 ms-md-5">
      <img src={logo} height="20" />
    </div>
    <div className="position-absolute w-100 h-100 overflow-hidden hero__video">
      <video className="w-100" autoPlay loop muted="muted">
        <source id="webm" src={videoBackground} type="video/webm" />
      </video>
    </div>
    <div className="hero__gradient" />
    <div className="hero__content w-100 pt-6 pt-md-5 text-center">
      <div className="container">
        {!team ? (
          <>
            <h1 className="hero__title mb-6 fw-medium mt-6 mt-md-0">Find Your Next Level Through Self Education</h1>
            <div className="row justify-content-center mb-5">
              <div className="col-12 col-md-7">
                <CustomSearchBox
                  translations={{
                    placeholder: 'Title, description, tags…',
                  }}
                  submit={<i className="bi bi-search" />}
                />
              </div>
            </div>
            <div>
              <h6 className="mb-3 text-muted fw-bold">Real People - Real Knowledge - Real Results</h6>
              <ButtonsRefinementList
                attribute="tags_array"
                searchable={false}
                translations={{
                  placeholder: 'Search for tags...',
                }}
                limit={10}
              />
            </div>
          </>
        ) : (
          <>
            <div class="row justify-content-center">
              <div class="col-xl-8">
                <div className="mb-2">
                  <CloudinaryImage className="rounded-circle mb-4" cloudinaryImageId={team.logo_image_value} width={72} height={72} />
                  <h4>{team.name}</h4>
                </div>
                <p>
                  Below you will find all of the "Live" products created by <b>{team.name}</b>.
                  Feel free to explore their "Product Library" and see which product or
                  products may serve you best in what you are looking for!
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  </div >
)

const Filters = (props) => (
  <div>
    <h4 className="directory__header mb-5 border border-1 border-top-0 border-end-0 border-start-0">Filters</h4>
    <div className="d-none" data-layout="desktop">
      <ClearRefinements
        translations={{
          reset: (
            <>
              <i className="bi bi-arrow-clockwise" />
              Clear filters
            </>
          ),
        }}
      />
    </div>
    <div data-layout="mobile">
      <ResultsNumberMobile />
    </div>
    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
        Free or Paid
      </label>
      <CustomRefinementList
        attribute="free_or_paid"
      />
    </div>
    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between mb-5">
        Price
      </label>
      <PriceSlider attribute="price" />
    </div>
    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
        Recurring Payments?
      </label>
      <CustomRefinementList
        attribute="recurring_payments"
      />
    </div>
    {document.showAuthorName && !props.team && <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
        Authors
      </label>
      <CustomRefinementList
        attribute="primary_expert_name"
        searchable={true}
        translations={{
          placeholder: 'Search for authors...',
        }}
        transformItems={items =>
          items.map(item => ({
            ...item,
            key: item.value,
            _highlightResult: {
              label:
                item._highlightResult ? { value: item._highlightResult.label.value.split('#')[0] } : null
            },
            label: (item.label.split('#')[0]),
          }))
        }
      />
    </div>}
    {!props.team && <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
        Organizations
      </label>
      <CustomRefinementList
        attribute="team_name"
        searchable={true}
        translations={{
          placeholder: 'Search for organizations...',
        }}
      />
    </div>}
    <div className="mt-5">
      <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
        Categories
      </label>
      <CustomRefinementList
        attribute="category_names"
        searchable={true}
        showMore
        showMoreLimit={50}
        translations={{
          placeholder: 'Search for categories...',
        }}
      />
    </div>
    {document.showRatingsEnabled && (
      <div className="mt-5">
        <label className="form-label fs-6 text-muted d-flex align-items-center justify-content-between">
          Rating
        </label>
        <RatingMenu
          attribute="rounded_rating"
          min={0}
        />
      </div>
    )}
  </div>
)

const Directory = props => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const searchClient = algoliasearch(
    props.applicationID,
    props.securedApiKey
  );

  const addRefinement = props.addRefinement;
  const Hit = React.useRef(props => {
    return (
      <SearchHit
        {...props}
        addRefinement={addRefinement}
      />
    )
  });

  function openFilters() {
    document.body.classList.add('filtering');
    window.scrollTo(0, 0);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('click', onClick);
  }

  function closeFilters() {
    document.body.classList.remove('filtering');
    containerRef.current.scrollIntoView();
    window.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('click', onClick);
  }

  function onKeyUp(event) {
    if (event.key !== 'Escape') {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="mastermind_popularity_desc"
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <Hero headerRef={headerRef} team={props.team} />
      <Configure
        attributesToSnippet={['short_description:-1']}
        snippetEllipsisText="..."
        removeWordsIfNoResults="allOptional"
      />
      <main className="container p-3 px-md-0 py-md-4" ref={containerRef}>
        <div className="row">
          <aside className="directory__aside col-auto d-none d-md-block">
            <div onKeyUp={onKeyUp}>
              <Filters {...props} />
            </div>
          </aside>

          <section className="directory__content col">
            <header className="row justify-content-end mb-4">
              <div className="col-6 col-md-auto directory__dropdown">
                <SortBy defaultRefinement="mastermind_popularity_desc" items={sortByValues} />
              </div>
              <div className="col-6 col-md-auto directory__dropdown">
                <HitsPerPage defaultRefinement={24} items={hitsPerPageValues} />
              </div>
            </header>
            <CustomHits hitComponent={Hit.current} />
            <NoResults />

            <footer>
              <Pagination
                padding={2}
                showFirst={false}
                showLast={false}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  ),
                }}
              />
            </footer>
          </section>
        </div>
      </main>
      <div className="position-fixed bottom-0 start-50 translate-middle-x p-3 pb-4 d-md-none">
        <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">
          <i className="bi bi-funnel me-2"></i> Filters
        </button>
      </div>
      <div className="offcanvas offcanvas-bottom h-100 d-md-none" tabIndex="-1" id="offcanvasFilters" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header justify-content-end">
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <Filters {...props} />
        </div>
        <div className="offcanvas-footer p-3">
          <button className="btn btn-primary w-100" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasFilters" aria-controls="offcanvasFilters">
            Apply Filters
          </button>
        </div>
      </div>
    </InstantSearch>
  );
};

export default hot(withURLSync(Directory));
