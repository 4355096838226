import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

const RefinementList = ({
  items,
  refine,
}) => (
  <div className="d-flex align-items-center justify-content-md-center refinment-tags">
    {items.map(item => (
      <button
        key={item.label}
        className={`btn ${item.isRefined ? 'btn-primary' : 'bg-white'} m-2 py-2 px-4 fs-6`}
        onClick={event => {
          event.preventDefault();
          refine(item.value);
        }}
      >
        {item.label}
      </button>
    ))}
  </div>
);

const ButtonsRefinementList = connectRefinementList(RefinementList);

export default ButtonsRefinementList;
