import React from 'react';
import { Highlight, connectRefinementList } from 'react-instantsearch-dom';

const SearchInput = ({ placeholder, value, onChange, onClearClick }) => (
  <div className="form-group position-relative mb-3">
    <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
    <input
      className="form-control ps-6 pe-5 bg-info border-0 fs-6"
      type="search"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
    {!!value.length && <div className="position-absolute top-50 end-0 translate-middle-y me-2" onClick={onClearClick}>
      <i className="bi bi-x fs-4"></i>
    </div>}
  </div>
)

const RefinementList = ({
  createURL,
  items,
  isFromSearch,
  refine,
  searchable,
  searchForItems,
  translations,
  ...props
}) => {
  const [value, setValue] = React.useState("");

  const handleSearchChange = (event) => {
    searchForItems(event.currentTarget.value)
    setValue(event.currentTarget.value)
  }

  const handleClearSearchClick = () => {
    searchForItems("")
    setValue("")
  }

  const replaceForCompliance = (label) => {
    let labelVal = label
    if (labelVal == 'Awaiting Review') {
      labelVal = 'Awaiting Compliance Review'
    } else if (labelVal == 'Approved') {
      labelVal = 'Compliance Approved'
    } else if (labelVal == 'Denied') {
      labelVal = 'Compliance Denied'
    }
    return labelVal
  }

  return (
    <div>
      {searchable && (
        <SearchInput
          value={value}
          placeholder={!!translations ? translations.placeholder : ""}
          translations={translations}
          onChange={handleSearchChange}
          onClearClick={handleClearSearchClick}
        />
      )}
      <div className="mb-n2">
        {items.map((item, key) => (
          <div key={item.label} className="d-flex align-items-center form-check mb-2">
            <input
              id={`${props.attribute}-${key}`}
              className="form-check-input mt-0"
              type="checkbox"
              value={item.isRefined}
              checked={item.isRefined}
              onChange={event => {
                event.preventDefault();
                refine(item.value);
              }}
            />
            <label className="form-check-label flex-grow-1 fs-6" htmlFor={`${props.attribute}-${key}`}>
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                replaceForCompliance(item.label)
              )}{' '}
            </label>
            <span className="me-0 ms-auto badge bg-info fs-6 text-black-50">{item.count}</span>
          </div>
        ))}
        {isFromSearch && items.length === 0 && <div className="mt-3 form-text">No results</div>}
      </div>
    </div>
  )
};

const CustomRefinementList = connectRefinementList(RefinementList);

export default CustomRefinementList;
