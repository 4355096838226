/* eslint @typescript-eslint/camelcase: ["error", { allow: ["free_shipping"] }], complexity: off */

import React, { Component } from 'react';
import qs from 'qs';

const updateAfter = 700;

const routeStateDefaultValues = {
  query: '',
  page: '1',
  category_names: undefined,
  tags_array: undefined,
  recurring_payments: undefined,
  price: '',
  sortBy: 'mastermind_popularity_desc',
  hitsPerPage: '16',
  team_name: '',
  primary_expert_name: '',
  compliance_status: '',
  compliance_filter: '',
};

const searchStateToURL = searchState => {
  const routeState = {
    query: searchState.query,
    page: String(searchState.page),
    category_names: searchState.refinementList && searchState.refinementList.category_names,
    tags_array: searchState.refinementList && searchState.refinementList.tags_array,
    recurring_payments: searchState.refinementList && searchState.refinementList.recurring_payments,
    team_name: searchState.refinementList && searchState.refinementList.team_name,
    primary_expert_name: searchState.refinementList && searchState.refinementList.primary_expert_name,
    compliance_status: searchState.refinementList && searchState.refinementList.compliance_status,
    compliance_filter: searchState.refinementList && searchState.refinementList.compliance_filter,
    price:
      searchState.range &&
      searchState.range.price &&
      `${searchState.range.price.min || ''}:${searchState.range.price.max ||
        ''}`,
    sortBy: searchState.sortBy,
    hitsPerPage:
      (searchState.hitsPerPage && String(searchState.hitsPerPage)) || undefined,
  };

  const { protocol, hostname, port = '', pathname, hash } = location;
  const portWithPrefix = port === '' ? '' : `:${port}`;
  const urlParts = location.href.match(/^(.*?)\/search/);
  const baseUrl =
    (urlParts && urlParts[0]) ||
    `${protocol}//${hostname}${portWithPrefix}${pathname}search`;

  const queryParameters = {};
  if (routeState.query && routeState.query !== routeStateDefaultValues.query) {
    queryParameters.query = encodeURIComponent(routeState.query);
  }
  if (routeState.page && routeState.page !== routeStateDefaultValues.page) {
    queryParameters.page = routeState.page;
  }
  if (
    routeState.category_names &&
    routeState.category_names !== routeStateDefaultValues.category_names
  ) {
    queryParameters.category_names = routeState.category_names.map(encodeURIComponent);
  }
  if (
    routeState.tags_array &&
    routeState.tags_array !== routeStateDefaultValues.tags_array
  ) {
    queryParameters.tags_array = routeState.tags_array.map(encodeURIComponent);
  }
  if (
    routeState.team_name &&
    routeState.team_name !== routeStateDefaultValues.team_name
  ) {
    queryParameters.team_name = encodeURIComponent(routeState.team_name)
  }
  if (
    routeState.primary_expert_name &&
    routeState.primary_expert_name !== routeStateDefaultValues.primary_expert_name
  ) {
    queryParameters.primary_expert_name = encodeURIComponent(routeState.primary_expert_name)
  }
  if (
    routeState.compliance_status &&
    routeState.compliance_status !== routeStateDefaultValues.compliance_status
  ) {
    queryParameters.compliance_status = encodeURIComponent(routeState.compliance_status)
  }
  if (
    routeState.recurring_payments &&
    routeState.recurring_payments !== routeStateDefaultValues.recurring_payments
  ) {
    queryParameters.recurring_payments = routeState.recurring_payments.map(encodeURIComponent);
  }
  if (routeState.price && routeState.price !== routeStateDefaultValues.price) {
    queryParameters.price = routeState.price;
  }
  if (
    routeState.sortBy &&
    routeState.sortBy !== routeStateDefaultValues.sortBy
  ) {
    queryParameters.sortBy = routeState.sortBy;
  }
  if (
    routeState.hitsPerPage &&
    routeState.hitsPerPage !== routeStateDefaultValues.hitsPerPage
  ) {
    queryParameters.hitsPerPage = routeState.hitsPerPage;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `${baseUrl}/${queryString}${hash}`;
};

const urlToSearchState = location => {
  const queryParameters = qs.parse(location.search.slice(1));
  const {
    query = '',
    page = 1,
    price,
    category_names = [],
    tags_array = [],
    recurring_payments = [],
    team_name = '',
    primary_expert_name = '',
    compliance_status = '',
    hitsPerPage,
    sortBy,
  } = queryParameters;
    // `qs` does not return an array when there's a single value.
  const allCategories = Array.isArray(category_names) ? category_names : [category_names].filter(Boolean);
  const allRecurringPayments = Array.isArray(recurring_payments) ? recurring_payments : [recurring_payments].filter(Boolean);
  const allTags = Array.isArray(tags_array) ? tags_array : [tags_array].filter(Boolean);

  const searchState = { range: {} };

  if (query) {
    searchState.query = decodeURIComponent(query);
  }
  if (page) {
    searchState.page = page;
  }

  var setCategories = []
  var setRecurringPayments = []
  var setTags = []

  if (allCategories.length) {
    setCategories = allCategories.map(decodeURIComponent);
  }
  if (allRecurringPayments.length) {
    setRecurringPayments = allRecurringPayments.map(decodeURIComponent);
  }
  if (allTags.length) {
    setTags = allTags.map(decodeURIComponent);
  }

  searchState.refinementList = {
    category_names: setCategories,
    recurring_payments: setRecurringPayments,
    tags_array: setTags,
    team_name: decodeURIComponent(team_name),
    primary_expert_name: decodeURIComponent(primary_expert_name),
    compliance_status: decodeURIComponent(compliance_status),
  };

  if (price && typeof price === 'string') {
    const [min, max = undefined] = price.split(':');
    searchState.range.price = {
      min: min || undefined,
      max: max || undefined,
    };
  }
  if (sortBy) {
    searchState.sortBy = sortBy;
  }

  if (hitsPerPage) {
    searchState.hitsPerPage = hitsPerPage;
  }

  return searchState;
};

const withURLSync = Directory =>
  class WithURLSync extends Component {
    state = {
      searchState: urlToSearchState(window.location),
    };

    componentDidMount() {
      window.addEventListener('popstate', this.onPopState);
    }

    componentWillUnmount() {
      clearTimeout(this.debouncedSetState);
      window.removeEventListener('popstate', this.onPopState);
    }

    onPopState = (e) => {
      this.setState({
        searchState: e.state || {},
      });
    }

    addRefinement = (attribute, value) => {
      const { searchState } = this.state;
      const { refinementList } = searchState;
      const attributeRefinements = refinementList[attribute] || (Array.isArray(value) ? [] : '')
      const newValue = Array.isArray(value) ? value[0] : value;
      if (attributeRefinements.indexOf(newValue) == -1) {
        refinementList[attribute] = attributeRefinements.concat(newValue)
        const newState = {
          ...searchState,
          refinementList: refinementList
        };
        this.onSearchStateChange(newState)
      }
    }

    onSearchStateChange = searchState => {
      clearTimeout(this.debouncedSetState);

      this.debouncedSetState = setTimeout(() => {
        window.history.pushState(
          searchState,
          null,
          searchStateToURL(searchState)
        );
      }, updateAfter);

      this.setState({ searchState });
    };

    render() {
      const { searchState } = this.state;

      return (
        <Directory
          {...this.props}
          searchState={searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={searchStateToURL}
          addRefinement={this.addRefinement}
        />
      );
    }
  };

export default withURLSync;
