import React, { useState, useEffect } from 'react';
import { connectRange } from 'react-instantsearch-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangeFilter.css';
import { DateRangePicker } from 'react-dates';

const DateRangeFilter = ({ min, max, refine, currentRefinement, canRefine }) => {
  if (!currentRefinement.min || !currentRefinement.max) {
    // Prevents some console errors if currentRefinements and min/max are not available yet
    return null;
  }

  const clamp = (number) => {
    return Math.min(Math.max(number, min), max);
  }

  const [state, setState] = useState({
    focusedInput: null,
    startDate: moment(currentRefinement.min * 1000),
    endDate: moment(currentRefinement.max * 1000),
  });

  useEffect(() => {
    if (canRefine) {
      setState({
        focusedInput: state.focusedInput,
        startDate: moment(currentRefinement.min * 1000),
        endDate: moment(currentRefinement.max * 1000)
      })
    }
  }, [currentRefinement]);

  const changeValues = (startDate, endDate) => {
    setState({ startDate: startDate, endDate: endDate });
    refine({ min: clamp(startDate.unix()), max: clamp(endDate.unix()) });
  }

  return (
    <DateRangePicker
      startDate={state.startDate}
      startDateId='startDate'
      endDate={state.endDate}
      endDateId='endDate'
      onDatesChange={({ startDate, endDate }) => changeValues(startDate, endDate)}
      focusedInput={state.focusedInput}
      onFocusChange={focusedInput => setState({ focusedInput, startDate: state.startDate, endDate: state.endDate })}
      minDate={moment(min * 1000)}
      maxDate={moment(max * 1000)}
      isOutsideRange={() => false}
      initialVisibleMonth={() => moment(max * 1000).subtract(1, 'month')}
      displayFormat="YYYY-MM-DD"
    />
  )
}

export default connectRange(DateRangeFilter);