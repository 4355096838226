import React from "react";
import { connectHits } from "react-instantsearch-dom";

const CustomHits = ({ children, hits, ...props }) => {
  return (
    <div className="row">
      {hits.map(hit => (
        <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3 mb-4" key={hit.objectID}>{props.hitComponent({ hit: hit })}</div>
      ))}
    </div>
  )
};
export default connectHits(CustomHits);
