import React from 'react'
import { connectSortBy } from 'react-instantsearch-dom'
import Dropdown from './Dropdown'

const SortBy = ({ currentRefinement, items, refine }) => {
  const triggerLabel = items.find(({ value }) => value === currentRefinement).label;

  const handleItemClick = itemValue => {
    refine(itemValue)
  }

  return (
    <Dropdown>
      <Dropdown.Trigger>{triggerLabel}</Dropdown.Trigger>
      <Dropdown.Menu items={items} onClick={handleItemClick} />
    </Dropdown>
  );
}

export default connectSortBy(SortBy)
