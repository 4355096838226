import React from 'react'

const DropdownTrigger = ({ children }) => (
  <button
    className="dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {children}
  </button>
)

const DropdownItem = ({
  children,
  className,
  onClick
}) => (
  <li>
    <a
      className={`dropdown-item ${className}`}
      onClick={onClick}
    >
      {children}
    </a>
  </li>
)

const DropdownMenu = ({
  items,
  onClick
}) => (
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    {items.map(({ isRefined, label, value }) => {
      const isActiveClass = isRefined ? "active" : "";

      const handleClick = () => {
        onClick(value)
      }

      return (
        <DropdownItem
          key={label}
          className={isActiveClass}
          onClick={handleClick}
        >
          {label}
        </DropdownItem>
      )
    })}
  </ul>
)

const Dropdown = ({ children }) => (
  <div className="dropdown dropdown-select">
    {children}
  </div>
);

export default Dropdown;

Dropdown.Trigger = DropdownTrigger;
Dropdown.Menu = DropdownMenu;
