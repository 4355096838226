import React from 'react'
import { connectHitsPerPage } from 'react-instantsearch-dom'
import Dropdown from './Dropdown'

const HitsPerPage = ({ currentRefinement, items, refine }) => {
  if (!items.length) return null;

  const item = items.find(({ value }) => value === currentRefinement)

  const triggerLabel = item ? item.label : items[1].label

  const handleItemClick = itemValue => {
    refine(itemValue)
  }

  return (
    <Dropdown>
      <Dropdown.Trigger>{triggerLabel}</Dropdown.Trigger>
      <Dropdown.Menu items={items} onClick={handleItemClick} />
    </Dropdown>
  );
}

export default connectHitsPerPage(HitsPerPage)
