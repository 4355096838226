import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const CustomSearchBox = ({ currentRefinement, refine, translations, ...props }) => (
  <form className="form-group position-relative" noValidate action="" role="search">
    <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-3" />
    <input
      className="form-control ps-6 pe-5"
      type="search"
      value={currentRefinement}
      placeholder={translations.placeholder}
      onChange={event => refine(event.currentTarget.value)}
    />
    {!!currentRefinement.length && (
      <div className="position-absolute top-50 end-0 translate-middle-y me-2" onClick={() => refine('')}>
        <i className="bi bi-x fs-4" />
      </div>
    )}
  </form>
)

export default connectSearchBox(CustomSearchBox);
