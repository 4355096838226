import React from 'react';
import {
  Highlight
} from 'react-instantsearch-dom';

const TagList = ({ hit, addRefinement }) => {
  return <span className="bootstrap-tagsinput">
    <br/>
    <strong> Tags: </strong>
    <span>
      {
        hit.tags_array.map((tag, index) =>
          <span className="hit-em badge badge-info" key={'tags-' + index }>
            <a
              onClick={event => {
                event.preventDefault();
                addRefinement('tags_array', [tag]);
              }}
            >
              <Highlight attribute={'tags_array['+ index + ']'} tagName="mark" hit={hit} />
            </a>
          </span>
        )
      }
    </span>
  </span>
};

export default TagList;
